import React from "react";
import { primaryColor } from "./Styles";
import { GetTeamImage } from "./LogoGetter";
import TeamTournamentDialog from "./TeamTournamentDialog";
import {
  DefunctTeams,
  SeasonString,
  TeamAndCityName,
  doRounding,
  getLinkToTeam,
} from "./Helpers";
import { Link } from "react-router-dom";
import MyDataGrid from "./MyDataGrid";

//Looking to have these columns:
//Team as Image (clickable into team dialog)
//Wins
//Losses
//Total point diff with appropriate color or whatever
//Total points
//Prev record (to tell user abt pool). not sure if i real y want this

//Sorted obvi by wins and then point diff
//Row highlighted according to result, green won the group, yellow qualified as wildcard.

//I think can sort by point diff and last season record only

export function makeColumn(headerName, field, width = 80, args) {
  const col = {
    field,
    headerName,
    width,
    headerClassName: "header-idk",
    headerAlign: "center",
    align: "center",
    ...args,
  };
  return col;
} //need to sort these accoring to final result, 1 at top then wc if it exists then wins then pd
export const gridLogoSize = "45px";
export default function PoolResultGrid({
  conferenceName,
  groupResultsSummary,
  conferenceBracketSeeds,
  groupLetter,
  season,
  tournamentData,
}) {
  const groupResultsSummaryAsRows = Object.entries(groupResultsSummary).map(
    (x) => {
      return {
        id: x[1].team,
        losses: groupResultsSummary.length - 1 - x[1].wins,
        lastSeasonWins: x[1].lastSeasonRecord[0],
        lastSeasonLosses: x[1].lastSeasonRecord[1],
        lastSeasonWinPercent:
          x[1].lastSeasonRecord[0] /
          (x[1].lastSeasonRecord[1] + x[1].lastSeasonRecord[0]),
        ...x[1],
      };
    }
  );

  const columns = [
    makeColumn("Team", "team", 80, {
      sortable: false,
      renderCell: (params) => (
        <TeamTournamentDialog
          isGroupStage
          team={params.row.team}
          tournamentData={tournamentData}
        >
          <Link
            to={getLinkToTeam(params.row.team)}
            //we have to manually cneter it a bit bc the link screws upthe positioning in the cell idrk
            style={{ marginTop: "6px" }}
          >
            <img
              alt={TeamAndCityName(params.row.team)}
              title={TeamAndCityName(params.row.team)}
              height={
                DefunctTeams.includes(params.row.team) ? "auto" : gridLogoSize
              }
              width={
                DefunctTeams.includes(params.row.team) ? "auto" : gridLogoSize
              }
              style={{
                objectFit: "cover",
                maxHeight: DefunctTeams.includes(params.row.team)
                  ? gridLogoSize
                  : gridLogoSize,

                maxWidth: DefunctTeams.includes(params.row.team)
                  ? gridLogoSize
                  : gridLogoSize,
              }}
              src={GetTeamImage(params.row.team, tournamentData.year)}
            />
          </Link>
        </TeamTournamentDialog>
      ),
    }),
    makeColumn("Wins", "wins", 60, {
      sortable: false,
      renderCell: (params) => (
        <p style={{ color: "greenyellow" }}>{params.row.wins}</p>
      ),
    }),
    makeColumn("Losses", "losses", 80, {
      sortable: false,
      renderCell: (params) => (
        <p
          style={{
            color: "darkred",
          }}
        >
          {params.row.losses}
        </p>
      ),
    }),
    makeColumn("Point Diff", "totalDiff", 70, {
      renderCell: (params) => (
        <p
          style={{
            color:
              params.row.totalDiff > 0
                ? "greenyellow"
                : params.row.totalDiff === 0
                ? "black"
                : "darkred",
          }}
        >
          {params.row.totalDiff > 0 ? "+" : ""}
          {params.row.totalDiff}
        </p>
      ),
    }),
    makeColumn("Total Points", "totalPoints", 80),
    makeColumn(
      SeasonString(season - 1) + " Record",
      "lastSeasonWinPercent",
      season === 2020 ? 130 : 99,
      {
        renderCell: (params) =>
          params.row.lastSeasonWins === 0 ? (
            <p style={{ color: primaryColor }}>N/A</p>
          ) : (
            <p style={{ color: primaryColor }}>
              {params.row.lastSeasonWins}-{params.row.lastSeasonLosses}
              {season === 2020
                ? " (" +
                  doRounding(
                    params.row.lastSeasonWins /
                      (params.row.lastSeasonWins + params.row.lastSeasonLosses)
                  ) +
                  "%)"
                : ""}
            </p>
          ),
      }
    ),
  ];
  return (
    <div
      style={{
        width: "fit-content",
        height: 425,
        margin: "auto",
      }}
    >
      <p
        style={{
          color: primaryColor,
          fontSize: 20,
          fontWeight: "bold",
          textAlign: "center",
        }}
      >
        {conferenceName} Group {groupLetter}
      </p>
      <MyDataGrid
        columns={columns}
        rows={groupResultsSummaryAsRows}
        getRowClassName={(params) =>
          [
            conferenceBracketSeeds["1"],
            conferenceBracketSeeds["2"],
            conferenceBracketSeeds["3"],
          ].includes(params.row.team)
            ? "groupWinner"
            : [conferenceBracketSeeds["WC"]].includes(params.row.team)
            ? "wildCard"
            : ""
        }
      />
    </div>
  );
}
