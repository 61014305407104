import React from "react";
import { validTournamentYears } from "./App";
import {
  DefunctTeams,
  ResultToImage,
  ResultToText,
  TeamAndCityName,
  doRounding,
  getLinkToTournamentYear,
  getTeamResult,
  getTournamentDataForYear,
} from "./Helpers";
import { CenteredP, Centerer } from "./Styles";
import { GetTeamImage } from "./LogoGetter";
import { Link } from "react-router-dom";
import TeamTournamentDialog from "./TeamTournamentDialog";

//This is gunna have a section saying their total results of each type w the percentages
//Maybe a section with the specific years they won overall
//Calander looking thing showing result by year with the header linking to the tournament. hovering will do the team dialog for that year

export const rowLength = 10;

export function Team({ team }) {
  var yearResults = [];
  var ind = 0;
  const resultCounter = {
    groupStage: 0,
    quarterfinalist: 0,
    semifinalist: 0,
    finalist: 0,
    champion: 0,
  };
  var totalParticipations = 0;
  const x = [...validTournamentYears];
  var results = [];
  for (const year of x.reverse()) {
    const i = x.indexOf(year);
    const data = getTournamentDataForYear(year);
    const result = getTeamResult(team, data);
    if (
      result !== "didNotExist" ||
      (team == "CHA" && [2002, 2003].includes(year))
    ) {
      resultCounter[result] += 1;
      totalParticipations += 1;

      //IF DID NOT EXIST SIKIP ADDDING THE YEAR. FOR CHA SHOULD LEAVE A GAP FOR THE 02 AND 03
      if (ind === 0) yearResults.push([]);
      yearResults[yearResults.length - 1].push(
        <YearResult
          key={year}
          year={year}
          result={
            result === "didNotExist" ? null : (
              <TeamTournamentDialog
                isSummaryPage
                team={team}
                //HAS TO KNOW IF ITS MORE THAN HALFWAY THRU THIS ROW OR NOT FIGURE IT OUT NOT THIS THO
                // isGroupStage={ind < rowLength * 0.5}
                tournamentData={data}
              >
                <Link to={getLinkToTournamentYear(year)}>
                  <img
                    src={ResultToImage(result)}
                    height="70px"
                    alt={ResultToText(result)}
                    title={ResultToText(result)}
                  />
                </Link>
              </TeamTournamentDialog>
            )
          }
        />
      );

      ind += 1;
      if (ind === rowLength) {
        yearResults[yearResults.length - 1].reverse();
        ind = 0;
      }
      if (ind === rowLength) ind = 0;
    }
  }
  if (yearResults[yearResults.length - 1].length % rowLength !== 0)
    yearResults[yearResults.length - 1].reverse();
  for (const res of Object.entries(resultCounter)) {
    results.push.apply(
      results,
      ImageResult(
        ResultToImage(res[0]),
        res[1] +
          " (" +
          doRounding(res[1] / totalParticipations).toString() +
          "%)",
        res[0],
        res[0] === "groupStage" || res[0] === "quarterfinalist"
      )
    );
  }
  return (
    <div>
      <h1
        style={{
          color: "white",
          fontSize: "45px",
          ...CenteredP,
          margin: "0px 0 40px 0",
          paddingTop: "55px",
        }}
      >
        The {TeamAndCityName(team)}
      </h1>
      <div
        style={{
          ...Centerer,
          border: "2px solid white",
          borderRadius: "200px",
          width: "250px",
          height: "250px",
          position: "relative",
        }}
      >
        <div
          style={{
            height: "fit-content",
            width: "fit-content",
            margin: "auto",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            alt={TeamAndCityName(team)}
            title={TeamAndCityName(team)}
            src={GetTeamImage(team)}
            width={DefunctTeams.includes(team) ? "auto" : "250px"}
            height={DefunctTeams.includes(team) ? "auto" : "250px"}
            style={{
              maxWidth: DefunctTeams.includes(team) ? "200px" : "250px",
              maxHeight: DefunctTeams.includes(team) ? "200px" : "250px",
              objectFit: DefunctTeams.includes(team) ? "auto" : "cover",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "fit-content",
          margin: "auto",
          marginTop: "30px",
        }}
      >
        {[
          results[4],
          results[5],
          results[6],
          results[7],
          results[8],
          results[9],
        ]}
      </div>
      <div style={{ display: "flex", width: "fit-content", margin: "auto" }}>
        {[results[0], results[1], results[2], results[3]]}
      </div>
      {/* <div style={{ display: "flex" }}>{results}</div> */}
      {yearResults.map((x, i) => (
        <div
          style={{ display: "flex", ...Centerer, marginTop: "40px" }}
          key={i}
        >
          {x}
        </div>
      ))}
    </div>
  );
}

export function YearResult({ year, result }) {
  return (
    <div
      style={{
        borderColor: "white",
        borderWidth: "0 1px 0 1px",
        borderStyle: "solid",
        // border: "0 1px 0 1px /solid white",
        color: "white",
        width: "110px",
        margin: "0 0px  ",
      }}
    >
      <>
        <Link
          to={getLinkToTournamentYear(year)}
          style={{ textDecoration: "none" }}
        >
          <p
            style={{
              ...CenteredP,
              backgroundColor: "rgba(0, 0, 160, .5)",
              margin: 0,
              padding: "10px 0",
              color: "white",
            }}
          >
            {year}
          </p>
        </Link>
        <div
          style={{
            height: "100px",
            width: "110px",
            backgroundColor: "rgba(255, 255, 255, .2)",
            display: "table-cell",
            verticalAlign: "middle",
          }}
        >
          {result ? <div style={Centerer}>{result}</div> : null}
        </div>
      </>
    </div>
  );
}

function ImageResult(src, percentage, result, shiftDown = false) {
  return [
    <div
      style={{
        position: "relative",
        height: "80px",
        width: "60px",
        marginTop: shiftDown ? "6px" : "0px",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <img
          src={src}
          height="40px"
          alt={ResultToText(result)}
          title={ResultToText(result)}
        />
      </div>
    </div>,
    <p
      style={{
        ...CenteredP,
        margin: "auto 0",
        color: "white",
        fontSize: "30px",
        marginRight: "20px",
      }}
    >
      {percentage}
    </p>,
  ];
}
