import React from "react";
import Backdrop from "./Backdrop";
import DialogBox from "./DialogBox";
import {
  DefunctTeams,
  TeamAndCityName,
  TeamsToNames,
  getLinkToTeam,
  getLinkToTournamentYear,
} from "./Helpers";
import { Link } from "react-router-dom";
import { GetTeamImage } from "./LogoGetter";

const fundamentalStyle = { margin: "20px 40px", textAlign: "center" };
const topStyle = {
  ...fundamentalStyle,
  color: "white",
  fontSize: "27px",
  fontWeight: "bold",
};
const subStyle = { ...fundamentalStyle, color: "white", fontSize: "23px" };
const textStyle = { ...fundamentalStyle, color: "white", fontSize: "19px" };

function BlehA({ children, href }) {
  return (
    <a href={href} style={{ color: "white" }} target="_blank">
      {children}
    </a>
  );
}

export default function GuideDialog({ onExit }) {
  return (
    <Backdrop onClick={onExit}>
      <DialogBox style={{ marginTop: "-200px" }}>
        <div
          style={{
            width: "1100px",
            height: "fit-content",
            backgroundColor: "gray",
          }}
        >
          <h1 style={topStyle}>Guide</h1>
          <p style={textStyle}>
            What if the NBA had always had an In Season Tournament? I simulated
            tournaments going back to the inception of the NBA in 1946 to find
            out.
          </p>
          <h2 style={subStyle}>Methodology</h2>
          <p style={textStyle}>
            To answer this question I treated a random sample of appropriate
            regular season games as though they had been played for the NBA Cup.
            I used the same grouping and results system as the actual
            tournament. <br />
            <br /> 1. Group the teams into pots by last seasons record.
            <br /> <br /> 2. Randomly fill out 3 pools for each conference based
            on those pots. <br /> <br /> 3. Find regular season games such that
            each team in each pool played each other once, with each team having
            2 home games. Because every team playes every other team at home,
            this is always possible. <br /> <br /> 4. Build out the bracket
            using the tie-breakers, then find a regular season game (that hasn't
            been used previously) and treat it as the elimination game betweent
            those two teams.
            <br />
            <br /> And voila! We have a way to see how past NBA cup's might have
            happened.
          </p>
          <h2 style={subStyle}>Features</h2>
          <p style={textStyle}>
            Each year has a page with it's bracket (e.g.{" "}
            <BlehA href={getLinkToTournamentYear(2014)}>2014</BlehA>,{" "}
            <BlehA href={getLinkToTournamentYear(1995)}>1995</BlehA>). Each team
            also has a page showing its results for the years it existsed (e.g.{" "}
            <BlehA href={getLinkToTeam("GSW")}>the Warriors</BlehA>,{" "}
            <BlehA href={getLinkToTeam("MIN")}>the Timberwolves</BlehA>).
            There's also a{" "}
            <BlehA href="/tournamentssummary">summary page</BlehA> showing the
            full hypothetical history of the tournament.
          </p>
          <h2 style={subStyle}>About Me</h2>
          <p style={textStyle}>
            This is a hobby project I've been working on for a few months now,
            it's been super fun! Feel free to check out my{" "}
            <BlehA href="https://www.youtube.com/channel/UCr6XWbThwSKXxejHUuL2Rww">
              youtube channel
            </BlehA>{" "}
            or <BlehA href="https://twitter.com/Jonny_Pelican">twitter</BlehA>{" "}
            (both made to document this project).
          </p>
          <p style={textStyle}>
            I sourced all of the season data from{" "}
            <BlehA href="https://app.balldontlie.io/">Ball Don't Lie</BlehA>. It
            was super easy to use, huge thanks to them!
          </p>
        </div>
      </DialogBox>
    </Backdrop>
  );
}
