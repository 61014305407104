import logo from "./logo.svg";
import "./App.css";
import Tournament from "./Tournament";
import { backColor } from "./Styles";
import { Route, Routes, redirect } from "react-router-dom";
import { teal } from "@mui/material/colors";
import { TeamsToNames } from "./Helpers";
import { Team } from "./Team";
import { TournamentsSummary } from "./TournamentsSummary";
import Header from "./Header";
import ScrollToTop from "./ScrollToTop";
import CupImage from "./Images/cupTrophy.png";
const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);
//Inclusive
export const validTournamentYears = range(1946, 2022, 1);
function App() {
  return (
    <div style={{}}>
      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          zIndex: -99,
        }}
      >
        <div style={{ width: "fit-content", margin: "auto" }}>
          <img
            width="auto"
            height="auto"
            src={CupImage}
            style={{
              margin: "auto",
              marginTop: "195px",
              marginLeft: "35px",
              opacity: 0.05,
            }}
          />
        </div>
      </div>
      <div
        style={{
          backgroundColor: backColor,
          height: "100vh",
          width: "100vw",
          position: "fixed",
          zIndex: -100,
        }}
      />
      <Header />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Tournament year={2022} />} />
        <Route path="/Tournament" element={null}>
          {/* Possibly this fucks with reacts ability to reerender tournament but i
          want each ot be its own page for seo? mb i don't know what i'm talking
          about */}
          {validTournamentYears.map((x) => (
            <Route
              path={x.toString()}
              key={x}
              element={<Tournament year={x} />}
            />
          ))}
        </Route>
        <Route
          path="/TournamentsSummary"
          element={<TournamentsSummary />}
        ></Route>
        <Route path="/Team" element={null}>
          {Object.keys(TeamsToNames).map((x) => (
            <Route path={x} key={x} element={<Team team={x} />} />
          ))}
        </Route>
      </Routes>

      {/* <p style={{ color: "white", fontSize: 10, textAlign: "right" }}>
        *This website is for educational purposes and unaffiliated with the NBA.
        All logos and histories belong to the NBA/ their teams.
      </p> */}
    </div>
  );
}

export default App;
