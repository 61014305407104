import React from "react";
import DialogBox from "./DialogBox";
import { CenteredP, Centerer, UnstyledA } from "./Styles";
import { Tooltip } from "@mui/material";
import {
  CityName,
  DefunctTeams,
  ResultToImage,
  ResultToText,
  SeasonString,
  SeedsToText,
  TeamAndCityName,
  bbrefLinkFromGame,
  doRounding,
  gamesBetweenTeamsNoMatchup,
  gamesInvolvingTeam,
  gamesInvolvingTeamNoMatchup,
  getKeyByValue,
  getTeamConference,
  getTeamResult,
  quarterfinalOpponentBySeed,
  semifinalOpponentBySeed,
} from "./Helpers";
import { GetTeamImage } from "./LogoGetter";
import GameOpener from "./GameOpener";
import { Team } from "./Team";

//When I click on a team as it relates to a specific tournament opens this dialog
//Header: team logo that links to the team page there
//Image to correspond what they got. gold, silver, bronze trophies, medal for quarter finalist, mb deflated basketball for ones that didn't qualify?
//Their result in this tournament. Options:
//Champion
//Finalist
//Semi-finalist
//Knockout round
//Didn't qualify
//If qualified, also says their seed. Eg: The Denver Nuggets won the tournament as the 2 seed!

//Then just lists their games in order of when they were played earliest to latest
//So 4 group play games, eg: Group Stage: 113- 105 at New Orleans on 11/3/2022. bolded the win or whatever. italicized black maybe.
//Quarter-final: vs Memphis (3) on 4/05/2022, 101- 99
//etc
//Not sure how I'll best communicate score. Teams score first? home teams score first? which one gets bolded?

//Could also add a mini-bracket to show how their tournament went w/ opponents at each stage but I think that's more work than I'm interested in

//Need to know:
//The team
//All pool games to get the ones it played and order them
//The seeds to know which seed it started and figure out the teams it played in order
//The results to know how many of those it got and where to stop
//All bracket games
export default function TeamTournamentDialog({
  team,
  tournamentData,
  children,
  isGroupStage = false,
  isSummaryPage = false,
}) {
  const conference = getTeamConference(team, tournamentData.year);
  const seeds = tournamentData[conference + "Seeds"];
  const year = tournamentData.year;
  const bracketResults = tournamentData.bracketResults;
  const allPoolGames = tournamentData.allPoolGames;
  const lastSeasonRecords = tournamentData.lastSeasonRecords;

  const allBracketGames = bracketResults.allBracketGames;
  const seed = getKeyByValue(seeds, team);
  const result = getTeamResult(team, tournamentData);
  const boxWidth = "430px";
  function resultText() {
    const seedText =
      " as the " + SeedsToText(seed) + (seed !== "WC" ? " seed" : "");
    return {
      champion:
        " won the " + SeasonString(year) + " tournament" + seedText + "!",
      finalist:
        " reached the finals of the " +
        SeasonString(year) +
        " tournament" +
        seedText +
        "!",
      semifinalist:
        " reached the semifinals of the " +
        SeasonString(year) +
        " tournament" +
        seedText +
        "!",
      quarterfinalist:
        " lost in the quarterfinals of the " +
        SeasonString(year) +
        " tournament" +
        seedText +
        ".",
      groupStage:
        " fell short of the knockout round of the " +
        SeasonString(year) +
        " tournament.",
    }[result];
  }
  function gameToText(game, stage) {
    const isHome = game.home_team.abbreviation === team;
    const opponent = isHome
      ? game.visitor_team.abbreviation
      : game.home_team.abbreviation;
    const myScore = isHome ? game.home_team_score : game.visitor_team_score;
    const opponsentScore = isHome
      ? game.visitor_team_score
      : game.home_team_score;

    return (
      <p
        style={{
          fontSize: 17,
          ...CenteredP,
          marginTop: "5px",
          marginBottom: "5px",
          color: "white",
        }}
      >
        {stage}:{" "}
        <GameOpener game={game}>
          {["Semifinals", "Finals"].includes(stage)
            ? "In Las Vegas vs."
            : isHome
            ? "vs."
            : "at"}{" "}
          {CityName(opponent)},{" "}
          <span
            style={{
              fontWeight: "bold",
              color: myScore > opponsentScore ? "lightgreen" : "orangered",
            }}
          >
            {myScore}
          </span>
          -{opponsentScore}
        </GameOpener>
      </p>
    );
  }
  const poolGames = gamesInvolvingTeam(team, allPoolGames);
  const bracketGames = gamesInvolvingTeamNoMatchup(team, allBracketGames);
  var quarterfinalGame,
    semifinalGame,
    finalGame = null;
  if (result !== "groupStage") {
    const quarterfinalOpponent = seeds[quarterfinalOpponentBySeed(seed)];
    quarterfinalGame = gamesBetweenTeamsNoMatchup(
      team,
      quarterfinalOpponent,
      bracketGames
    )[0];
    if (result !== "groupStage" && result !== "quarterfinalist") {
      const semifinalOpponent =
        bracketResults[conference + semifinalOpponentBySeed(seed)];
      semifinalGame = gamesBetweenTeamsNoMatchup(
        team,
        semifinalOpponent,
        bracketGames
      )[0];
      if (result === "finalist" || result === "champion") {
        const finalsOpponent =
          bracketResults[
            conference === "east" ? "westSemisWinner" : "eastSemisWinner"
          ];
        finalGame = gamesBetweenTeamsNoMatchup(
          team,
          finalsOpponent,
          bracketGames
        )[0];
      }
    }
  }
  var recordText = "";
  if (!(team in lastSeasonRecords)) {
    //shoukdn't really break here but i think it's not breaking anything, think it's somequirt of houw i did the tresults generation that screwed it up
  }
  if (!(team in lastSeasonRecords) || lastSeasonRecords[team][0] === 0)
    recordText = "N/A";
  else
    recordText =
      lastSeasonRecords[team][0] +
      "-" +
      lastSeasonRecords[team][1] +
      " " +
      (year === 2020
        ? " (" +
          doRounding(
            lastSeasonRecords[team][0] /
              (lastSeasonRecords[team][0] + lastSeasonRecords[team][1])
          ) +
          "%)"
        : "");

  const box = (
    <DialogBox>
      <div
        style={{ width: boxWidth, border: "10px solid rgba(0, 0, 160, .5)" }}
      >
        <div
          style={{
            backgroundColor: "rgba(0, 0, 160, .5)",
            margin: "0 0 10px 0",
          }}
        >
          <div style={Centerer}>
            <img
              src={GetTeamImage(team)}
              height={DefunctTeams.includes(team) ? "auto" : "75px"}
              width={DefunctTeams.includes(team) ? "auto" : "75px"}
              style={{
                objectFit: "cover",
                maxHeight: DefunctTeams.includes(team) ? "75px" : "75px",
                marginBottom: "8px",
                maxWidth: DefunctTeams.includes(team) ? "75px" : "75px",
              }}
              alt={TeamAndCityName(team)}
              title={TeamAndCityName(team)}
            />
          </div>
        </div>
        <div style={Centerer}>
          <img
            alt={ResultToText(result)}
            title={ResultToText(result)}
            width="100px"
            style={{ margin: "auto" }}
            src={ResultToImage(result)}
          />
        </div>
        <p
          style={{
            ...CenteredP,
            fontWeight: "bold",
            fontSize: 20,
            margin: "20px 20px",
            fontStyle: "italic",
          }}
        >
          The {TeamAndCityName(team)} {resultText()}
        </p>
        <p
          style={{
            fontSize: 20,
            ...CenteredP,
            marginTop: "5px",
            marginBottom: "15px",
            color: "white",
            fontStyle: "italic",
          }}
        >
          {SeasonString(year - 1)} Record: {recordText}
        </p>
        {poolGames.map((x) => gameToText(x[1], "Group Stage"))}
        {quarterfinalGame && gameToText(quarterfinalGame, "Quarterfinals")}
        {semifinalGame && gameToText(semifinalGame, "Semifinals")}
        {finalGame && gameToText(finalGame, "Finals")}
      </div>
    </DialogBox>
  );

  //ADD SOME MARGIN TO FIX THE WEIRD LEFT BEHAVIOR FOR EAST IDK WHATS UP THERE
  //guessing it's mui not knowing exactly how big it is. bleh
  var tooltipPlacement = null;
  if (isSummaryPage) tooltipPlacement = "top";
  else if (isGroupStage)
    tooltipPlacement = conference == "west" ? "left" : "right";
  else tooltipPlacement = conference == "west" ? "right" : "left";
  const leftOffset =
    tooltipPlacement == "left" ? 150 : tooltipPlacement == "top" ? 10 : 0;
  const topOffset = tooltipPlacement == "top" ? 0 : 0;
  return (
    <Tooltip
      enterDelay={500}
      leaveDelay={200}
      placement={tooltipPlacement}
      title={box}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              //magic number to help w the fact tha mui dont know how big the title is(i think)
              offset: [topOffset, leftOffset],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
}
