import React from "react";
import {
  CityName,
  DefunctTeams,
  ResultToImage,
  ResultToText,
  TeamAndCityName,
  TeamsToNames,
  getLinkToTeam,
  getTeamResult,
  getTournamentDataForYear,
} from "./Helpers";
import { validTournamentYears } from "./App";
import { YearResult, rowLength } from "./Team";
import { CenteredP, Centerer, primaryColor } from "./Styles";
import { GetTeamImage } from "./LogoGetter";
import TeamTournamentDialog from "./TeamTournamentDialog";
import { Link } from "react-router-dom";
import TeamWinCountDataGrid from "./TeamWinCountsDataGrid";

export function TournamentsSummary() {
  const teamResults = {};
  const yearWinners = {};
  var yearResults = [];
  var ind = 0;
  const reversed = [...validTournamentYears].reverse();
  for (const year of reversed) {
    const data = getTournamentDataForYear(year);

    for (const team of Object.keys(TeamsToNames)) {
      const result = getTeamResult(team, data);
      if (result === "champion") yearWinners[year] = team;
      if (!(team in teamResults))
        teamResults[team] = {
          groupStage: 0,
          quarterfinalist: 0,
          semifinalist: 0,
          finalist: 0,
          champion: 0,
        };
      teamResults[team][result] += 1;
    }
    if (ind === 0) yearResults.push([]);
    yearResults[yearResults.length - 1].push(
      <YearResult
        key={year}
        year={year}
        result={
          <TeamTournamentDialog
            isSummaryPage
            tournamentData={data}
            team={yearWinners[year]}
          >
            <Link to={getLinkToTeam(yearWinners[year])}>
              <img
                alt={TeamAndCityName(yearWinners[year])}
                title={TeamAndCityName(yearWinners[year])}
                src={GetTeamImage(yearWinners[year], year)}
                height={
                  DefunctTeams.includes(yearWinners[year]) ? "auto" : "90px"
                }
                width={
                  DefunctTeams.includes(yearWinners[year]) ? "auto" : "90px"
                }
                style={{
                  objectFit: "cover",
                  maxHeight: DefunctTeams.includes(yearWinners[year])
                    ? "90px"
                    : "90px",

                  maxWidth: DefunctTeams.includes(yearWinners[year])
                    ? "90px"
                    : "90px",
                }}
              />
            </Link>
          </TeamTournamentDialog>
        }
      />
    );

    ind += 1;
    if (ind === rowLength) {
      yearResults[yearResults.length - 1].reverse();
      ind = 0;
    }
  }

  if (yearResults[yearResults.length - 1].length % rowLength !== 0)
    yearResults[yearResults.length - 1].reverse();
  const teamResultsRows = [];
  for (const result of Object.entries(teamResults)) {
    teamResultsRows.push({ id: result[0], team: result[0], ...result[1] });
  }
  teamResultsRows.sort(function (a, b) {
    const aCity = CityName(a.team);
    const bCity = CityName(b.team);
    return aCity > bCity ? 1 : bCity > aCity ? -1 : 0;
  });

  return (
    <div>
      <h1
        style={{
          color: "white",
          fontSize: "45px",
          ...CenteredP,
          margin: "0px 0 40px 0",
          paddingTop: "55px",
        }}
      >
        Tournaments Summary
      </h1>
      <div
        style={{
          ...Centerer,
          width: "300px",
          height: "300px",
          border: "2px solid white",
          borderRadius: "300px",
        }}
      >
        <img
          alt={ResultToText("champion")}
          title={ResultToText("champion")}
          style={{ marginTop: "40px", marginLeft: "40px" }}
          src={ResultToImage("champion")}
          width="220px"
        />
      </div>
      {yearResults.map((x, i) => (
        <div
          style={{ display: "flex", ...Centerer, marginTop: "40px" }}
          key={i}
        >
          {yearResults[i]}
        </div>
      ))}
      <div style={{ height: "40px" }} />
      <h1
        style={{
          color: primaryColor,
          textAlign: "center",
          borderColor: primaryColor,
          borderWidth: "0px 0px 1px 0px",
          borderStyle: "solid",
          width: "60vw",
          margin: "auto",
          marginBottom: "30px",
        }}
        // data-tooltip-content={<TeamToudrnamentDialog />}
        // data-tooltip-id='test'>
      >
        Overall Summary
      </h1>
      <TeamWinCountDataGrid rows={teamResultsRows} />
    </div>
  );
}
