import Gold from "./Images/Gold.png";
import Silver from "./Images/Silver.png";
import Bronze from "./Images/Bronze.png";
import Medal from "./Images/Medal.png";
import KnockedOut from "./Images/KnockedOut.png";

export function ResultToImage(result) {
  return {
    groupStage: KnockedOut,
    quarterfinalist: Medal,
    semifinalist: Bronze,
    finalist: Silver,
    champion: Gold,
  }[result];
}

export function ResultToText(result) {
  return {
    groupStage: "Group Stage",
    quarterfinalist: "Quarter-Finals",
    semifinalist: "Semi-Finals",
    finalist: "Finals",
    champion: "Champion",
  }[result];
}

export function doRounding(number) {
  return parseFloat((number * 100).toFixed(1));
}

export function FormattedDate(day, month, year) {
  return month.toString() + "/" + day.toString() + "/" + year.toString();
}

export function SeasonString(year) {
  return year.toString() + "-" + (year + 1).toString();
}

export function SeedsToText(seed) {
  return {
    1: "one",
    2: "two",
    3: "three",
    WC: "wild card",
  }[seed];
}

export const TeamsToNames = {
  DEN: ["Denver", "Nuggets"],
  MEM: ["Memphis", "Grizzlies"],
  LAL: ["Los Angeles", "Lakers"],
  PHI: ["Philadelphia", "76ers"],
  POR: ["Portland", "Trailblazers"],
  WAS: ["Washington", "Wizards"],
  HOU: ["Houston", "Rockets"],
  IND: ["Indiana", "Pacers"],
  CHA: ["Charlotte", "Hornets"],
  BKN: ["Brooklyn", "Nets"],
  ATL: ["Atlanta", "Hawks"],
  MIA: ["Miami", "Heat"],
  MIN: ["Minnesota", "Timberwolves"],
  GSW: ["Golden State", "Warriors"],
  NYK: ["New York", "Knicks"],
  ORL: ["Orlando", "Magic"],
  MIL: ["Milwaukee", "Bucks"],
  CHI: ["Chicago", "Bulls"],
  DAL: ["Dallas", "Mavericks"],
  SAC: ["Sacramento", "Kings"],
  OKC: ["Oklahoma City", "Thunder"],
  NOP: ["New Orleans", "Pelicans"],
  LAC: ["Los Angeles", "Clippers"],
  CLE: ["Cleveland", "Cavaliers"],
  PHX: ["Phoenix", "Suns"],
  TOR: ["Toronto", "Raptors"],
  UTA: ["Utah", "Jazz"],
  SAS: ["San Antonio", "Spurs"],
  DET: ["Detroit", "Pistons"],
  BOS: ["Boston", "Celtics"],

  //exctinct teams
  BLB: ["Baltimore", "Bullets"],

  AND: ["Anderson", "Packers"],
  CLR: ["Cleveland", "Rebels"],
  DNN: ["Denver", "Nuggets"],
  DTF: ["Detroit", "Falcons"],

  INJ: ["Indianapolis", "Jets"],
  PIT: ["Pittsburgh", "Ironmen"],
  SHE: ["Sheboygan", "Red Skins"],
  TRH: ["Toronto", "Huskies"],
  WAT: ["Waterloo", "Hawks"],
  PRO: ["Providence", "Steamrollers"],
  CHS: ["Chicago", "Stags"],
  INO: ["Indianapolis", "Olympians"],
  STB: ["St. Louis", "Bombers"],
  WSC: ["Washington", "Capitols"],
};

export const DefunctTeams = [
  "BLB",
  "AND",
  "CLR",
  "DNN",
  "DTF",
  "INJ",
  "PIT",
  "SHE",
  "TRH",
  "WAT",
  "PRO",
  "CHS",
  "INO",
  "STB",
  "WSC",
];

export function TeamAndCityName(team) {
  return TeamsToNames[team][0] + " " + TeamsToNames[team][1];
}

export function CityName(team) {
  return TeamsToNames[team][0];
}

export function TeamName(team) {
  return TeamsToNames[team][1];
}

export function getTeamConference(team, year) {
  if (team == "CHA" && year == 1989) return "west";
  else if (team == "OLR" && year == 1990) return "east";
  else if (team == "HOU" && year >= 1972 && year <= 1979) return "east";
  else if (team == "SAS" && year <= 1979) return "east";
  else if (team == "CHI" && year <= 1979) return "west";
  else if (team == "MIL" && year <= 1979 && year >= 1970) return "west";
  else if (team == "UTA" && year <= 1978) return "east";
  else if (team == "IND" && year <= 1978) return "west";
  else if (team == "DET" && year <= 1977) return "west";
  else if (team == "SAC" && year <= 1971 && year >= 1962) return "east";
  else if (team == "ATL" && year <= 1969) return "west";
  else if (team == "DET" && year <= 1966) return "west";
  else if (team == "WAS" && year <= 1965) return "west";
  else if (team == "GSW" && year <= 1961) return "east";
  else if (team == "BLB" && year == 1947) return "west";
  else if (team == "WSC" && year == 1947) return "west";
  return TeamsToConference[team] ? "west" : "east";
}

//West is 1, east 0
export const TeamsToConference = {
  LAL: 1,
  PHI: 0,
  POR: 1,
  WAS: 0,
  HOU: 1,
  IND: 0,
  CHA: 0,
  BKN: 0,
  ATL: 0,
  MIA: 0,
  MIN: 1,
  GSW: 1,
  NYK: 0,
  DEN: 1,
  ORL: 0,
  MIL: 0,
  CHI: 0,
  DAL: 1,
  SAC: 1,
  OKC: 1,
  NOP: 1,
  LAC: 1,
  CLE: 0,
  MEM: 1,
  PHX: 1,
  TOR: 0,
  UTA: 1,
  SAS: 1,
  DET: 0,
  BOS: 0,
  //whacko ones
  BLB: 0,
  AND: 1,
  CLR: 1,
  DNN: 1,
  DTF: 1,

  INJ: 1,
  PIT: 1,
  SHE: 1,
  TRH: 0,
  WAT: 1,
  PRO: 0,
  CHS: 1,
  INO: 1,
  STB: 1,
  WSC: 0,
};

export function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

//Wants a list of games and gets the games that had both these teams playing IGNORING ORDER HO
export function gamesBetweenTeams(teamA, teamB, gamesList) {}

export function gamesInvolvingTeam(team, gamesList) {
  return gamesList.filter((x) => x[0].includes(team));
}

export function gamesInvolvingTeamNoMatchup(team, gamesList) {
  return gamesList.filter(
    (x) =>
      x.home_team.abbreviation == team || x.visitor_team.abbreviation == team
  );
}

export function quarterfinalOpponentBySeed(seed) {
  return {
    1: "WC",
    2: "3",
    3: "2",
    WC: "1",
  }[seed];
}

export function semifinalOpponentBySeed(seed) {
  return {
    1: "TwoThreeWinner",
    2: "OneWcWinner",
    3: "OneWcWinner",
    WC: "TwoThreeWinner",
  }[seed];
}

export function gamesBetweenTeamsNoMatchup(teamA, teamB, gamesList) {
  return gamesList.filter(
    (x) =>
      (x.visitor_team.abbreviation == teamA ||
        x.home_team.abbreviation == teamA) &&
      (x.visitor_team.abbreviation == teamB ||
        x.home_team.abbreviation == teamB)
  );
}

//Going to make it only take tournament data at some point dont worry abt it
export function getTeamResult(team, tournamentData) {
  const conference = getTeamConference(team, tournamentData.year);
  //get the result or whatver

  const bracketResults = tournamentData.bracketResults;
  const seeds =
    conference === "east" ? tournamentData.eastSeeds : tournamentData.westSeeds;
  const seed = getKeyByValue(seeds, team);
  var result = "";
  if (bracketResults["overallWinner"] == team) result = "champion";
  else if ([bracketResults[conference + "SemisWinner"]].includes(team))
    result = "finalist";
  else if (
    [
      bracketResults[conference + "OneWcWinner"],
      bracketResults[conference + "TwoThreeWinner"],
    ].includes(team)
  )
    result = "semifinalist";
  else if (seed !== undefined) result = "quarterfinalist";
  else if (TeamExistedInTournament(team, tournamentData)) result = "groupStage";
  else result = "didNotExist";
  return result;
}

export function getTournamentDataForYear(year) {
  const tournamentData = require("./Results/Results" +
    year.toString() +
    ".json");
  const lastSeasonRecords = {};
  for (const conf of ["east", "west"]) {
    for (const group of tournamentData[conf + "SortedResultsSummaries"]) {
      for (const team of group) {
        lastSeasonRecords[team.team] = team.lastSeasonRecord;
      }
    }
  }
  tournamentData.lastSeasonRecords = lastSeasonRecords;
  return tournamentData;
}

export function getLinkToTeam(team) {
  return "/team/" + team;
}

export function getLinkToTournamentYear(year) {
  return "/tournament/" + year;
}

export function TeamExistedInTournament(team, tournamentData) {
  return (
    tournamentData.allPoolGames.filter(
      (x) =>
        x[0].substring(0, 3).includes(team) ||
        x[0].substring(3, 6).includes(team)
    ).length > 0
  );
}

// https://www.basketball-reference.com/boxscores/200704120LAL.html
export function bbrefLinkFromGame(game) {
  var baseUrl = "https://www.basketball-reference.com/boxscores/";
  baseUrl +=
    game.year.toString() +
    (game.month < 10 ? "0" : "") +
    game.month.toString() +
    (game.day < 10 ? "0" : "") +
    game.day.toString() +
    "0" +
    //have to do this bc some of the old ones i generate don't have season just year dont worry abt it shhh
    getPastTeamAbbreviation(
      game.home_team.abbreviation,
      game.month <= 8 ? game.year - 1 : game.year
    ) +
    ".html";
  return baseUrl;
}

const bbrefPastTeamAbbreviations = {
  LAL: [
    [1948, "MNL"],
    [1960, "LAL"],
  ],
  PHI: [
    [1949, "SYR"],
    [1960, "PHI"],
  ],

  WAS: [
    [1961, "CHP"],
    [1962, "CHZ"],
    [1963, "BAL"],
    [1973, "CAP"],
    [1974, "WSB"],
    [1997, "WAS"],
  ],

  HOU: [
    [1967, "SDR"],
    [1971, "HOU"],
  ],

  CHA: [
    [1988, "CHH"],
    [2004, "CHA"],
    [2014, "CHO"],
  ],

  BKN: [
    [1976, "NYN"],
    [1977, "NJN"],
    [2012, "BRK"],
  ],

  ATL: [
    [1949, "TRI"],
    [1951, "MLH"],
    [1955, "STL"],
    [1968, "ATL"],
  ],

  GSW: [
    [1946, "PHW"],
    [1962, "SFW"],
    [1971, "GSW"],
  ],

  SAC: [
    [1948, "ROC"],
    [1957, "CIN"],
    [1972, "KCO"],
    [1975, "KCK"],
    [1985, "SAC"],
  ],

  OKC: [
    [1967, "SEA"],
    [2008, "OKC"],
  ],

  NOP: [
    [2002, "NOH"],
    [2005, "NOK"],
    [2007, "NOH"],
    [2013, "NOP"],
  ],

  LAC: [
    [1970, "BUF"],
    [1978, "SDC"],
    [1984, "LAC"],
  ],

  MEM: [
    [1995, "VAN"],
    [2001, "MEM"],
  ],

  PHX: [[1968, "PHO"]],

  UTA: [
    [1974, "NOJ"],
    [1979, "UTA"],
  ],

  DET: [
    [1948, "FTW"],
    [1957, "DET"],
  ],
};

export function getPastTeamAbbreviation(team, year) {
  if (!(team in bbrefPastTeamAbbreviations)) return team;
  const history = bbrefPastTeamAbbreviations[team];
  const reversed = [...history].reverse();
  for (const abbrev of reversed) {
    const oldAbbrevYear = abbrev[0];
    const oldAbbrev = abbrev[1];
    if (oldAbbrevYear <= year) return oldAbbrev;
  }
}

export const TournamentMVPs = {
  2022: "Nikola Jokić",
  2021: "Luka Dončić",
  2020: "Giannis Antentokounmpo",
  2019: "Giannis Antentokounmpo",
  2018: "Giannis Antentokounmpo",
  2017: "Kevin Durant",
  2016: "Isaiah Thomas",
  2015: "Stephen Curry",
  2014: "LeBron James",
  2013: "Paul George",
  2012: "Kevin Durant",
  2011: "LeBron James",
  2010: "Dwight Howard",
  2009: "Carmelo Anthony",
  2008: "Kobe Bryant",
  2007: "Rasheed Wallace",
  2006: "Stephon Marbury",
  2005: "Richard Hamilton",
  2004: "Chauncey Billups",
  2003: "Yao Ming",
  2002: "Jason Kidd",
  2001: "Shaquille O'Neal",
  2000: "David Robinson",
  1999: "Alonzo Mourning",
  1998: "Gary Payton",
  1997: "Michael Jordan",
  1996: "Joe Dumars",
  1995: "Michael Jordan",
  1994: "Hakeem Olajuwon",
  1993: "Dominique Wilkins",
  1992: "Shawn Kemp",
  1991: "Michael Jordan",
  1990: "Charles Barkley",
  1989: "Isiah Thomas",
  1988: "Magic Johnson",
  1987: "Alex English",
  1986: "James Worthy",
  1985: "Larry Bird",
  1984: "Artis Gilmore",
  1983: "Kareem Abdul-Jabbar",
  1982: "Larry Bird",
  1981: "Julius Erving",
  1980: "Julius Erving",
  1979: "Larry Bird",
  1978: "Elvin Hayes",
  1977: "Julius Erving",
  1976: "Julius Erving",
  1975: "John Havlicek",
  1974: "Austin Carr",
  1973: "John Havlicek",
  1972: "Kareem Abdul-Jabbar",
  1971: "Willis Reed",
  1970: "Wes Unseld",
  1969: "Willis Reed",
  1968: "Oscar Robertson",
  1967: "Earl Monroe",
  1966: "Bill Russel",
  1965: "Red Kerr",
  1964: "Bill Russel",
  1963: "Bill Russel",
  1962: "Bill Russel",
  1961: "Bill Russel",
  1960: "Oscar Robertson",
  1959: "Bill Russel",
  1958: "Bill Russel",
  1957: "Bill Russel",
  1956: "Kenny Sears",
  1955: "Neil Johnston",
  1954: "Clyde Lovellette",
  1953: "Neil Johnston",
  1952: "Bobby Wazner",
  1951: "Dolph Schayes",
  1950: "Ed Macauley",
  1949: "Dolph Schayes",
  1948: "Fred Scolari",
  1947: "Dick Holub",
  1946: "Bob Feerick",
};
