import React from "react";

export default function DialogBox({ children, style }) {
  return (
    <div
      style={{
        width: "fit-content",
        height: "fit-content",
        backgroundColor: "gray",
        border: "4px solid white",
        ...style,
      }}
    >
      {children}
    </div>
  );
}
