import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TwitterIcon from "./Images/twitter.png";
import YoutubeIcon from "./Images/youtubeIcon.png";
//home2 is a bit greyer i prefer it
import HomeIcon from "./Images/home2.png";
import { primaryColor } from "./Styles";
import TeamsNavigateDialog from "./TeamsNavigateDialog";
import GuideDialog from "./GuideDialog";

const LinkStyle = {
  margin: "auto",
  width: "120px",
  textAlign: "center",
  fontSize: "18px",
  color: "white",
  textDecoration: "none",
  cursor: "pointer",
};

const imgHeight = "26px";

export default function Header() {
  const [teamsOpen, setTeamsOpen] = useState(false);
  const [guideOpen, setGuideOpen] = useState(false);
  useEffect(() => {
    setTeamsOpen(false);
  }, [window.location.href]);
  return (
    <div
      style={{
        display: "flex",
        borderBottom: "1px solid " + primaryColor,
        backgroundColor: "rgba(173, 216, 230, .4)",
      }}
    >
      {guideOpen ? <GuideDialog onExit={() => setGuideOpen(false)} /> : null}
      <div style={{ flex: 1 }}>
        <Link to="tournament/2022">
          <img
            alt="Home"
            title="Home"
            style={{ margin: "4px", marginTop: "7px", marginLeft: "10px" }}
            src={HomeIcon}
            height={imgHeight}
          />
        </Link>
      </div>
      <div style={{ flex: 0.5, display: "flex" }}>
        {/* <div style={{ width: "120px" }}> */}
        <Link style={{ ...LinkStyle }} to="/tournamentssummary">
          Summary
        </Link>
        <p style={{ ...LinkStyle }} onClick={() => setGuideOpen(true)}>
          Guide
        </p>
      </div>
      {/* </div> */}
      <div style={{ flex: 1, display: "flex", justifyContent: "right" }}>
        <a href="https://twitter.com/Jonny_Pelican">
          <img
            alt="My Twitter"
            title="Follow for more content like this!"
            style={{ margin: "5px", marginTop: "9px" }}
            height={imgHeight}
            src={TwitterIcon}
          />
        </a>
        <a href="https://www.youtube.com/channel/UCr6XWbThwSKXxejHUuL2Rww">
          <img
            alt="My Youtube Channel"
            title="Click for more like this!"
            style={{ margin: "5px", marginTop: "9px" }}
            src={YoutubeIcon}
            height={imgHeight}
          />
        </a>
      </div>
    </div>
  );
}
