import React from "react";
import PoolResultGrid from "./PoolResultGrid";
import { primaryColor } from "./Styles";
import Bracket from "./Bracket";
import { SeasonString, getTournamentDataForYear } from "./Helpers";
import { Link } from "react-router-dom";
import { validTournamentYears } from "./App";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export default function Tournament({ year }) {
  const tournamentData = getTournamentDataForYear(year);

  function makeGroupsFromResultSummaries(conferenceId) {
    return tournamentData[conferenceId + "SortedResultsSummaries"].map(
      (x, i) => (
        <PoolResultGrid
          tournamentData={tournamentData}
          conferenceName={capitalizeFirstLetter(conferenceId)}
          season={year}
          key={i}
          groupResultsSummary={x}
          conferenceBracketSeeds={tournamentData[conferenceId + "Seeds"]}
          groupLetter={["A", "B", "C"][i]}
        />
      )
    );
  }
  const eastGroups = makeGroupsFromResultSummaries("east");
  const westGroups = makeGroupsFromResultSummaries("west");
  const YearLinkStyle = {
    width: "fit-content",
    color: "white",
    textDecoration: "none",
    fontSize: "60px",
  };

  const prevLink = (
    <span style={{ minWidth: "40px", display: "inline-block" }}>
      {year !== validTournamentYears[0] ? (
        <Link style={YearLinkStyle} to={"/tournament/" + (year - 1).toString()}>
          ◂
        </Link>
      ) : (
        ""
      )}
    </span>
  );
  const nextLink = (
    <span style={{ minWidth: "40px", display: "inline-block" }}>
      {year !== validTournamentYears[validTournamentYears.length - 1] ? (
        <Link style={YearLinkStyle} to={"/tournament/" + (year + 1).toString()}>
          ▸
        </Link>
      ) : (
        ""
      )}
    </span>
  );
  return (
    <div>
      <h1
        style={{
          color: "white",
          textAlign: "center",
          fontSize: "40px",
          width: "80vw",
          margin: "auto",
          marginBottom: "10px",
        }}
      >
        {prevLink}
        {SeasonString(year)} In-Season Tournament{nextLink}
      </h1>

      <Bracket
        tournamentData={tournamentData}
        eastSeeds={tournamentData.eastSeeds}
        westSeeds={tournamentData.westSeeds}
        bracketResults={tournamentData.bracketResults}
      />
      <div style={{ height: "100px" }} />
      <h1
        style={{
          color: primaryColor,
          textAlign: "center",
          borderColor: primaryColor,
          borderWidth: "0px 0px 1px 0px",
          borderStyle: "solid",
          width: "60vw",
          margin: "auto",
        }}
        // data-tooltip-content={<TeamToudrnamentDialog />}
        // data-tooltip-id='test'>
      >
        Group Stage
      </h1>

      <div style={{ display: "flex", width: "70vw", margin: "auto" }}>
        <div style={{ flex: 1 }}>{eastGroups}</div>
        <div style={{ flex: 1 }}>{westGroups}</div>
      </div>
    </div>
  );
}
