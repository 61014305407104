import React from "react";
import { bbrefLinkFromGame } from "./Helpers";

export default function GameOpener({ game, children, style }) {
  return (
    <a
      target="_blank"
      style={{ color: "#deebff", ...style }}
      href={game ? bbrefLinkFromGame(game) : ""}
    >
      {children}
    </a>
  );
}
