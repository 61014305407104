import React from "react";
import { GetTeamImage } from "./LogoGetter";
import {
  DefunctTeams,
  TeamAndCityName,
  TournamentMVPs,
  getKeyByValue,
  getLinkToTeam,
} from "./Helpers";
import TeamTournamentDialog from "./TeamTournamentDialog";
import { Link } from "react-router-dom";
import GameOpener from "./GameOpener";
import CupImage from "./Images/cupTrophy.png";

export default function Bracket({
  eastSeeds,
  westSeeds,
  bracketResults,
  tournamentData,
}) {
  return (
    <div>
      <div style={{ height: "100px" }} />
      {/* need some space above it bc i do the line math starting at 0 y but tehn there are images that go a bit above */}
      <LinesAndStuff
        tournamentData={tournamentData}
        eastSeeds={eastSeeds}
        westSeeds={westSeeds}
        bracketResults={bracketResults}
      />
    </div>
  );
}

function LinesAndStuff({
  eastSeeds,
  westSeeds,
  bracketResults,
  tournamentData,
}) {
  const horizLength = 200;
  const vertHeight = 100;
  const lineColor = "white";
  const conferenceGap = 30;

  const imgMargin = 0;
  const imgSize = 50;
  const longModifier = 2.4;
  const inBracketFontSize = 19;
  const upperSemisLineHeight = vertHeight / 2;
  const lowerSemisLineHeight = vertHeight / 2 + vertHeight * longModifier;
  const totalBracketWidth = 6 * horizLength + conferenceGap;
  const totalBracketHeight = vertHeight + vertHeight * longModifier;
  //So if its the inital columns for either one we pass in 1-WC for initialId, if later pass in the like OneWcWinner and combine it with conference

  const bustedSeeds = ["BLB", "AND", "STB", "TRH", "CLR"];

  function makeBracketHalf(conference) {
    const seeds = conference === "east" ? eastSeeds : westSeeds;
    const flipHorizontally = conference === "west";
    function HorizontalLine({
      x,
      y,
      initialSeedId,
      laterRoundId,
      prevAboveInitialSeedId,
      prevBelowInitialSeedId,
      prevAboveLaterRoundId,
      prevBelowLaterRoundId,
    }) {
      const laterKey = laterRoundId ? conference + laterRoundId : "";
      const team = initialSeedId
        ? seeds[initialSeedId]
        : bracketResults[laterKey];
      const underText = initialSeedId
        ? initialSeedId
        : getKeyByValue(seeds, team);

      var aboveScore = "";
      var belowScore = "";
      var matchup = null;
      if (prevAboveInitialSeedId || prevAboveLaterRoundId) {
        const prevAboveTeam = prevAboveInitialSeedId
          ? seeds[prevAboveInitialSeedId]
          : bracketResults[conference + prevAboveLaterRoundId];
        const prevBelowTeam = prevBelowInitialSeedId
          ? seeds[prevBelowInitialSeedId]
          : bracketResults[conference + prevBelowLaterRoundId];
        matchup = bracketResults.allBracketGames.filter((x) => {
          const teams = x.home_team.abbreviation + x.visitor_team.abbreviation;
          return teams.includes(prevAboveTeam) && teams.includes(prevBelowTeam);
        })[0];
        aboveScore =
          matchup.home_team.abbreviation === prevAboveTeam
            ? matchup.home_team_score
            : matchup.visitor_team_score;
        belowScore =
          matchup.home_team.abbreviation === prevBelowTeam
            ? matchup.home_team_score
            : matchup.visitor_team_score;
      }
      if (flipHorizontally) {
        const rightCoord = x + horizLength;
        x = totalBracketWidth - rightCoord;
      }
      return (
        <>
          <div
            style={{
              position: "absolute",
              top: y - imgSize - imgMargin,
              left:
                x +
                8 +
                (flipHorizontally ? 0 : horizLength - imgSize - imgMargin - 8),
            }}
          >
            <TeamTournamentDialog tournamentData={tournamentData} team={team}>
              <Link to={getLinkToTeam(team)}>
                <img
                  alt={TeamAndCityName(team)}
                  title={TeamAndCityName(team)}
                  height={DefunctTeams.includes(team) ? "auto" : imgSize + "px"}
                  width={DefunctTeams.includes(team) ? "auto" : imgSize + "px"}
                  style={{
                    objectFit: "cover",
                    maxHeight: DefunctTeams.includes(team)
                      ? imgSize + "px"
                      : imgSize + "px",

                    maxWidth: DefunctTeams.includes(team)
                      ? imgSize + "px"
                      : imgSize + "px",
                  }}
                  src={GetTeamImage(team, tournamentData.year)}
                />
              </Link>
            </TeamTournamentDialog>
            <p
              style={{
                margin: 0,
                marginTop: bustedSeeds.includes(team) ? "18px" : "3px",
                marginRight: "8px",
                textAlign: "center",
                color: lineColor,
                fontSize: inBracketFontSize,
                fontStyle: "italic",
              }}
            >
              {underText}
            </p>
          </div>
          <GameOpener
            style={{
              textDecoration: "none",
              position: "absolute",
              top: y - imgSize - imgMargin + 22,
              left:
                x +
                8 +
                (flipHorizontally ? horizLength - imgSize - imgMargin - 8 : 0),
            }}
            game={matchup ? matchup : null}
          >
            <p
              style={{
                margin: 0,
                marginTop: "3px",
                textAlign: "center",
                color: "white",
                fontSize: inBracketFontSize,
                fontStyle: "italic",
                fontWeight: aboveScore > belowScore ? "bold" : "normal",
              }}
            >
              {aboveScore}
            </p>
            <p
              style={{
                margin: 0,
                marginTop: "3px",
                textAlign: "center",
                fontSize: inBracketFontSize,
                fontStyle: "italic",
                fontWeight: aboveScore < belowScore ? "bold" : "normal",
                color: "white",
              }}
            >
              {belowScore}
            </p>
          </GameOpener>
          <div
            style={{
              position: "absolute",
              width: horizLength + "px",
              height: "0px",
              borderStyle: "solid",
              borderColor: lineColor,
              borderWidth: "1px 0px 0px 0px",
              top: y,
              left: x,
            }}
          />
        </>
      );
    }
    function VerticalLine({ x, y, longer = false }) {
      if (flipHorizontally) {
        const rightCoord = x;
        x = totalBracketWidth - rightCoord;
      }
      return (
        <div
          style={{
            position: "absolute",
            width: "0px",
            height: (longer ? longModifier : 1) * vertHeight + "px",
            borderStyle: "solid",
            borderColor: lineColor,
            borderWidth: "0px 1px 0px 0px",
            top: y,
            left: x,
          }}
        />
      );
    }
    return (
      <>
        <HorizontalLine x={0} y={0} initialSeedId={"1"} />
        <VerticalLine x={horizLength} y={0} />
        <HorizontalLine x={0} y={vertHeight} initialSeedId={"WC"} />
        <HorizontalLine
          x={horizLength}
          y={upperSemisLineHeight}
          laterRoundId={"OneWcWinner"}
          prevAboveInitialSeedId={"1"}
          prevBelowInitialSeedId={"WC"}
        />
        <VerticalLine x={horizLength * 2} y={vertHeight / 2} longer />
        <HorizontalLine
          x={horizLength}
          y={lowerSemisLineHeight}
          prevAboveInitialSeedId={"2"}
          prevBelowInitialSeedId={"3"}
          laterRoundId={"TwoThreeWinner"}
        />
        <VerticalLine
          x={horizLength}
          y={lowerSemisLineHeight - vertHeight / 2}
        />
        <HorizontalLine
          x={0}
          y={lowerSemisLineHeight - vertHeight / 2}
          initialSeedId={"2"}
        />
        <HorizontalLine
          x={0}
          y={lowerSemisLineHeight + vertHeight / 2}
          initialSeedId={"3"}
        />

        <HorizontalLine
          x={horizLength * 2}
          y={upperSemisLineHeight + (vertHeight * longModifier) / 2}
          prevAboveLaterRoundId={"OneWcWinner"}
          prevBelowLaterRoundId={"TwoThreeWinner"}
          laterRoundId={"SemisWinner"}
        />
      </>
    );
  }
  //Should refactor the code that gets the game to be more reusable
  const eastWinner = bracketResults.eastSemisWinner;
  const westWinner = bracketResults.westSemisWinner;

  const matchup = bracketResults.allBracketGames.filter((x) => {
    const teams = x.home_team.abbreviation + x.visitor_team.abbreviation;
    return teams.includes(eastWinner) && teams.includes(westWinner);
  })[0];
  const eastWinnerScore =
    matchup.home_team.abbreviation === eastWinner
      ? matchup.home_team_score
      : matchup.visitor_team_score;
  const westWinnerScore =
    matchup.home_team.abbreviation === westWinner
      ? matchup.home_team_score
      : matchup.visitor_team_score;
  return (
    <>
      <div
        style={{
          width: totalBracketWidth + "px",
          margin: "auto",
          height: totalBracketHeight + "px",
          position: "relative",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: -70,
            left: totalBracketWidth / 2 - 300 / 2,
            width: "300px",
          }}
        >
          <div style={{ width: "fit-content", margin: "auto" }}>
            <TeamTournamentDialog
              tournamentData={tournamentData}
              team={bracketResults.overallWinner}
            >
              <Link to={getLinkToTeam(bracketResults.overallWinner)}>
                <img
                  alt={TeamAndCityName(bracketResults.overallWinner)}
                  title={TeamAndCityName(bracketResults.overallWinner)}
                  height={
                    DefunctTeams.includes(bracketResults.overallWinner)
                      ? "auto"
                      : "100px"
                  }
                  width={
                    DefunctTeams.includes(bracketResults.overallWinner)
                      ? "auto"
                      : "100px"
                  }
                  style={{
                    objectFit: "cover",
                    maxHeight: DefunctTeams.includes(
                      bracketResults.overallWinner
                    )
                      ? "100px"
                      : "100px",

                    maxWidth: DefunctTeams.includes(
                      bracketResults.overallWinner
                    )
                      ? "100px"
                      : "100px",
                  }}
                  src={GetTeamImage(
                    bracketResults.overallWinner,
                    tournamentData.year
                  )}
                />
              </Link>
            </TeamTournamentDialog>
          </div>
          <p
            style={{
              margin: 0,
              marginTop: "0px",
              textAlign: "center",
              color: lineColor,
              fontSize: 30,
              fontStyle: "italic",
            }}
          >
            Champion!
          </p>
          <p
            style={{
              margin: 0,
              marginTop: "0px",
              textAlign: "center",
              color: lineColor,
              fontSize: 20,
              fontStyle: "italic",
            }}
          >
            Cup MVP: {TournamentMVPs[tournamentData.year]}
          </p>
          <GameOpener
            style={{
              display: "flex",
              margin: "auto",
              marginTop: "120px",
              width: "fit-content",
              textDecoration: "none",
            }}
            game={matchup}
          >
            <p
              style={{
                color: lineColor,
                fontSize: inBracketFontSize,
                fontWeight:
                  eastWinnerScore > westWinnerScore ? "bold" : "normal",
                fontStyle: "italic",
              }}
            >
              {eastWinnerScore}
            </p>
            <p style={{ color: lineColor, fontSize: inBracketFontSize }}>-</p>
            <p
              style={{
                color: lineColor,
                fontSize: inBracketFontSize,
                fontStyle: "italic",
                fontWeight:
                  eastWinnerScore < westWinnerScore ? "bold" : "normal",
              }}
            >
              {westWinnerScore}
            </p>
          </GameOpener>
        </div>
        {makeBracketHalf("east")} {makeBracketHalf("west")}
      </div>
    </>
  );
}
