import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { primaryColor } from "./Styles";

export default function MyDataGrid({
  columns,
  rows,
  initialState = {},
  getRowClassName = () => {},
}) {
  return (
    <DataGrid
      columns={columns}
      rows={rows}
      autoHeight
      hideFooter
      disableColumnMenu
      columnHeaderHeight={80}
      disableRowSelectionOnClick
      getRowClassName={getRowClassName}
      sortingOrder={["desc", "asc", null]}
      sx={{
        fontWeight: 800,
        fontSize: 16,
        border: 4,
        color: primaryColor,
        backgroundColor: "rgba(255, 255, 255, .3)",
        borderColor: primaryColor,
        "& .MuiDataGrid-cell": {
          borderColor: "white",
          borderWidth: 3,
          // add more css for customization
        },
        ".groupWinner": {
          backgroundColor: "rgba(17, 255, 0, .15)",
        },
        ".groupWinner.Mui-hovered": {
          backgroundColor: "rgba(17, 255, 0, .3) !important",
        },
        ".wildCard": {
          backgroundColor: "rgba(255, 14, 0, .15)",
        },
        ".wildCard.Mui-hovered": {
          backgroundColor: "rgba(255, 14, 0, .3) !important",
        },
        ".MuiDataGrid-columnHeaders": {
          borderColor: "white",
          borderWidth: 3,
        },

        ".MuiDataGrid-columnHeaderTitle": {
          fontWeight: "900",
          lineHeight: "normal",
          textWrap: "wrap",
          textAlign: "center",
        },
        ".MuiDataGrid-columnSeparator": {
          display: "none",
        },
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none !important",
        },
      }}
    />
  );
}
