import React from "react";
import { Portal } from "react-portal";

export default function Backdrop({ children, onClick }) {
  return (
    <Portal>
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(0, 0, 0, .5)",
        }}
        onMouseDown={onClick}
      >
        <div
          //idk why the relative 50% is necessary idk not gunna deal w it
          style={{
            width: "fit-content",
            height: "fit-content",
            margin: "auto",
            position: "relative",
            top: "25%",
          }}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    </Portal>
  );
}
