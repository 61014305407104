export const backColor = "#282c34";
export const primaryColor = "#61dafb";

export const Centerer = {
  margin: "auto",
  width: "fit-content",
};

export const CenteredP = {
  textAlign: "center",
  height: "fit-content",
};

export const UnstyledA = {
  textDecoration: "none",
};
