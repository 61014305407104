import BKN from "./TeamLogos/BKN.svg";
import BOS from "./TeamLogos/BOS.svg";
import NYK from "./TeamLogos/NYK.svg";
import PHI from "./TeamLogos/PHI.svg";
import TOR from "./TeamLogos/TOR.svg";

import CHI from "./TeamLogos/CHI.svg";
import CLE from "./TeamLogos/CLE.svg";
import DET from "./TeamLogos/DET.svg";
import IND from "./TeamLogos/IND.svg";
import MIL from "./TeamLogos/MIL.svg";

import ATL from "./TeamLogos/ATL.svg";
import CHA from "./TeamLogos/CHA.svg";
import MIA from "./TeamLogos/MIA.svg";
import ORL from "./TeamLogos/ORL.svg";
import WAS from "./TeamLogos/WAS.svg";

import GSW from "./TeamLogos/GSW.svg";
import LAC from "./TeamLogos/LAC.svg";
import LAL from "./TeamLogos/LAL.svg";
import PHX from "./TeamLogos/PHX.svg";
import SAC from "./TeamLogos/SAC.svg";

import DAL from "./TeamLogos/DAL.svg";
import HOU from "./TeamLogos/HOU.svg";
import MEM from "./TeamLogos/MEM.svg";
import NOP from "./TeamLogos/NOP.svg";
import SAS from "./TeamLogos/SAS.svg";

import DEN from "./TeamLogos/DEN.svg";
import MIN from "./TeamLogos/MIN.svg";
import OKC from "./TeamLogos/OKC.svg";
import POR from "./TeamLogos/POR.svg";
import UTA from "./TeamLogos/UTA.svg";

import BLB from "./TeamLogos/BLB.webp";
import AND from "./TeamLogos/AND.jpeg";
import CLR from "./TeamLogos/CLR.png";
import DNN from "./TeamLogos/DNN.png";
import DTF from "./TeamLogos/DTF.webp";
import INJ from "./TeamLogos/INJ.png";
import PIT from "./TeamLogos/PIT.webp";
import SHE from "./TeamLogos/SHE.png";
import TRH from "./TeamLogos/TRH.png";
import WAT from "./TeamLogos/WAT.jpeg";
import PRO from "./TeamLogos/PRO.png";
import CHS from "./TeamLogos/CHS.webp";
import INO from "./TeamLogos/INO.png";
import STB from "./TeamLogos/STB.png";
import WSC from "./TeamLogos/WSC.png";

import ATL1949 from "./TeamLogosYears/ATL1949.webp";
import ATL1951 from "./TeamLogosYears/ATL1951.png";
import ATL1955 from "./TeamLogosYears/ATL1955.png";

import BKN1976 from "./TeamLogosYears/BKN1976.png";

import CHA2004 from "./TeamLogosYears/CHA2004.png";

import HOU1967 from "./TeamLogosYears/HOU1967.webp";

import LAL1948 from "./TeamLogosYears/LAL1948.jpeg";

import PHI1949 from "./TeamLogosYears/PHI1949.webp";

import WAS1961 from "./TeamLogosYears/WAS1961.webp";

import DET1948 from "./TeamLogosYears/DET1948.png";
import MEM1995 from "./TeamLogosYears/MEM1995.png";
import NOP2002 from "./TeamLogosYears/NOP2002.png";
import OKC1967 from "./TeamLogosYears/OKC1967.png";
import SAC1948 from "./TeamLogosYears/SAC1948.png";
import SAC1957 from "./TeamLogosYears/SAC1957.webp";
import SAC1972 from "./TeamLogosYears/SAC1972.webp";
import LAC1970 from "./TeamLogosYears/LAC1970.png";

// import BUF from "./TeamLogos/BUF.gif";

import NoImage from "./TeamLogos/NOIMAGE.png";

const abbrevImageMap = {
  CHI,
  CLE,
  DET,
  IND,
  MIL,
  ATL,
  CHA,
  MIA,
  ORL,
  WAS,
  BKN,
  BOS,
  NYK,
  PHI,
  TOR,
  GSW,
  LAC,
  LAL,
  PHX,
  SAC,
  DAL,
  HOU,
  MEM,
  NOP,
  SAS,
  DEN,
  MIN,
  OKC,
  POR,
  UTA,

  BLB,
  AND,
  CLR,
  DNN,
  DTF,

  INJ,
  PIT,
  SHE,
  TRH,
  WAT,
  PRO,
  CHS,
  INO,
  STB,
  WSC,
};

//rule is just going to be when the team name changes - any racist ones

const abbrevImageMapByYear = {
  ATL: [
    [1968, ATL],
    [1955, ATL1955],
    [1951, ATL1951],
    [1949, ATL1949],
  ],
  LAL: [
    [1960, LAL],
    [1948, LAL1948],
  ],
  PHI: [
    [1960, PHI],
    [1949, PHI1949],
  ],

  WAS: [
    [1997, WAS],
    [1961, WAS1961],
  ],

  HOU: [
    [1971, HOU],
    [1967, HOU1967],
  ],

  CHA: [
    [2014, CHA],
    [2004, CHA2004],
    [1988, CHA],
  ],

  BKN: [
    [2012, BKN],
    [1976, BKN1976],
  ],

  SAC: [
    [1948, SAC1948],
    [1957, SAC1957],
    [1972, SAC1972],
    [1985, SAC],
  ],

  OKC: [
    [1967, OKC1967],
    [2008, OKC],
  ],

  NOP: [
    [2002, NOP2002],
    [2013, NOP],
  ],

  LAC: [
    [1970, LAC1970],
    [1984, LAC],
  ],

  MEM: [
    [1995, MEM1995],
    [2001, MEM],
  ],

  DET: [
    [1948, DET1948],
    [1957, DET],
  ],
};

export function GetTeamImage(teamAbbreviation, year = 2022) {
  // if (teamAbbreviation in abbrevImageMapByYear) {
  //   const options = abbrevImageMapByYear[teamAbbreviation];
  //   for (const option of options) {
  //     const optionYear = option[0];
  //     if (year >= optionYear) return option[1];
  //   } } else
  if (teamAbbreviation in abbrevImageMap)
    return abbrevImageMap[teamAbbreviation];

  return NoImage;
}
