import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import MyDataGrid from "./MyDataGrid";
import { gridLogoSize, makeColumn } from "./PoolResultGrid";
import { Link } from "react-router-dom";
import {
  DefunctTeams,
  ResultToImage,
  ResultToText,
  TeamAndCityName,
  TeamName,
  getLinkToTeam,
} from "./Helpers";
import { GetTeamImage } from "./LogoGetter";

export default function TeamWinCountDataGrid({ rows }) {
  function resultRenderHeader(stage) {
    return () => (
      <img
        title={ResultToText(stage)}
        alt={ResultToText(stage)}
        src={ResultToImage(stage)}
        width="30px"
      />
    );
  }

  const dayInMonthComparator = (v1, v2) => v1[1] - v2[1];
  const resultColumnWidth = 90;
  const columns = [
    makeColumn("Team", "team", 80, {
      sortable: false,
      valueGetter: (params) => params.row.team,
      sortComparator: dayInMonthComparator,

      renderCell: (params) => (
        <Link
          to={getLinkToTeam(params.row.team)}
          //we have to manually cneter it a bit bc the link screws upthe positioning in the cell idrk
          style={{ marginTop: "6px" }}
        >
          <img
            alt={TeamAndCityName(params.row.team)}
            title={TeamAndCityName(params.row.team)}
            height={
              DefunctTeams.includes(params.row.team) ? "auto" : gridLogoSize
            }
            width={
              DefunctTeams.includes(params.row.team) ? "auto" : gridLogoSize
            }
            style={{
              objectFit: "cover",
              maxHeight: DefunctTeams.includes(params.row.team)
                ? gridLogoSize
                : gridLogoSize,

              maxWidth: DefunctTeams.includes(params.row.team)
                ? gridLogoSize
                : gridLogoSize,
            }}
            src={GetTeamImage(params.row.team)}
          />
        </Link>
      ),
    }),
    makeColumn("", "champion", resultColumnWidth, {
      renderHeader: resultRenderHeader("champion"),
    }),
    makeColumn("", "finalist", resultColumnWidth, {
      renderHeader: resultRenderHeader("finalist"),
    }),
    makeColumn("", "semifinalist", resultColumnWidth, {
      renderHeader: resultRenderHeader("semifinalist"),
    }),

    makeColumn("", "quarterfinalist", resultColumnWidth, {
      renderHeader: resultRenderHeader("quarterfinalist"),
    }),

    makeColumn("", "groupStage", resultColumnWidth, {
      renderHeader: resultRenderHeader("groupStage"),
    }),
  ];
  rows.sort((a, b) => {
    if (DefunctTeams.includes(b.id) && !DefunctTeams.includes(a.id)) return -1;
    if (DefunctTeams.includes(a.id) && !DefunctTeams.includes(b.id)) return 1;
    return TeamName(a.id) - TeamName(b.id);
  });

  return (
    <div style={{ width: "550px", margin: "auto" }}>
      <MyDataGrid rows={rows} columns={columns} />
    </div>
  );
}
